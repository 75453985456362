
html { 
    height: 100%;
}

body {
    height: 100%;
}


.hey {
    background: #f8f9fa;
}

.ef{
    text-decoration: underline;
    color: #3d85d8 !important

}

.ef2{
    color: #3d85d8;
    padding: 3%;
    text-decoration: underline;
}

.imgStyle{      
    font-size:25px;
    text-align:center;
    color: white;
    position: absolute;
    top: 60%;
    left:45%
        
        }    
@media screen and (max-width: 600px) {
        .imgStyle {
            top: 57%;
            left:37%
            }
          }       

/**
 * border radius
 */

.btn,
.jumbotron,
.nav :hover {
    border-radius: 0px;
}

/* cart badge */

.cart-badge,
.cart-badge:hover {
    border-radius: 50%;
    padding: 2px;
    font-size: 12px;
    font-style: italic;
    background: #000;
}
/**
 * single product page - product name
 */

.name {
    background: indigo;
    color: #fff;
    font-weight: bold;
}

/* black shade form 10-1 */
.black-10 {
    background: #f2f2f2;
}
.black-9 {
    background: #e6e6e6;
}
.black-8 {
    background: #d9d9d9;
}
.black-7 {
    background: #cccccc;
}
.black-6 {
    background: #bfbfbf;
}
.black-5 {
    background: #b3b3b3;
}


/**
 * product image on card
 */

 .product-img {
    width: 100%;
    height: 15rem;
    object-fit: cover;
    transition: transform .3s;
    
} 

.product-img:hover {
    transform: scale(1.1)
}


.product-img-sp {
    text-align: center;
    
    
}



.jumbotron h2 {
    margin-top: -20px;
}

@media only screen and (max-width: 600px) {
    .jumbotron h2 {
        margin-top: 10px;
    }
}

/**
 * jumbotron animation
 */

 /* #ee7752, #e73c7e, #23a6d5, #23d5ab */

.jumbotron {
    height: 180%;
    color: #fff;
    background: linear-gradient(-45deg, #b8d2eb, #67a0e0, #42acd3, #3d85d8);
    background-size: 400% 400%;
    -webkit-animation: Gradient 15s ease infinite;
    -moz-animation: Gradient 15s ease infinite;
    animation: Gradient 15s ease infinite;
    text-align: center;
}

@-webkit-keyframes Gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@-moz-keyframes Gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes Gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
